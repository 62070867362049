import * as types from "../../actionType";

const initialState = {
  saveCloseToggle: false,
  getSrtDataList: [],
  massSearchFilterResult: [],
  unsolicitedMassSearchFilterResult: [],
  seasonSearchFilterResult: [],
  brandComment: "",
  statusSavedData: "",
  exFactoryDateVar: "",
  reqRecDateVar: "",
  srtSaveAllData: {},
  searchLoader: false,
  brandCommentLoader: false,
  brandStatusSaverLoader: false,
  designStatusSaverLoader: false,
  statusSaverLoader: false,
  srtBrandNames: "Dickies",
  SrtSeaonsNames: "",
  srtRowIndex: 0,
  srtExportData: [],
  srtExportArrayData: [],
  srtSelectedTableRows: [],
  ReportGeneration: [],
  srtRefreshSpinner: false,
  srtCommentUpdate: {},
  srtSearchData: [],
  srtSeasonSearch: [],
  srtBulkSaveResponse: {},
  srtUnsolicitedList: [],
  srtUnsolicitedExportData: [],
  srtUnsolicitedBulkUpload: [],
  srtUnsolicitedEdits: [],
  clearData: {
    startDate: null,
    endDate: null,
  },
};

export const SrtReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_CLOSE_TOGGLE:
      return {
        ...state,
        saveCloseToggle: action.payload,
        loading: false,
      };
    case types.CLEAR_DATE_ON_BTN_CLICK:
      return {
        ...state,
        clearData: action.payload,
        loading: false,
      };
    case types.RESET_SEASON_FILTER:
      return {
        ...state,
        seasonSearchFilterResult: action.payload,
        loading: false,
      };
    case types.RESET_SRT_DATA:
      return {
        ...state,
        getSrtDataList: action.payload,
        loading: false,
      };
    case types.RESET_MASS_FILTER:
      return {
        ...state,
        massSearchFilterResult: action.payload,
        loading: false,
      };
    case types.RESET_MASS_UNSOLICITED_FILTER:
      return {
        ...state,
        unsolicitedMassSearchFilterResult: action.payload,
        loading: false,
      };
    case types.EXFACTORY_DATE:
      return {
        ...state,
        exFactoryDateVar: action.payload,
        loading: false,
      };
    case types.REQ_REC_DATE:
      return {
        ...state,
        reqRecDateVar: action.payload,
        loading: false,
      };
    case types.SRT_BRANDS:
      return {
        ...state,
        srtBrandNames: action.payload,
        loading: false,
      };
    case types.SRT_SEASONS:
      return {
        ...state,
        SrtSeaonsName: action.payload,
        loading: false,
      };
    case types.ROW_INDEX_FOR_AUTOSCROLL:
      return {
        ...state,
        srtRowIndex: action.payload,
        loading: false,
      };
    case types.SELECT_UNSELECT_SRT_TABLE_ROWS:
      return {
        ...state,
        srtSelectedTableRows: action.payload,
        loading: false,
      };
    case types.GET_SRT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
        srtRefreshSpinner: true,
      };
    case types.GET_SRT_LIST_SUCCESS:
      return {
        ...state,
        getSrtDataList: action.payload,
        loading: false,
        searchLoader: false,
        srtRefreshSpinner: false,
      };
    case types.GET_SRT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        getSrtDataList: [],
        searchLoader: false,
        srtRefreshSpinner: false,
      };
    case types.MASS_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.MASS_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        massSearchFilterResult: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.MASS_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        massSearchFilterResult: [],
        searchLoader: false,
      };
    case types.UNSOLICITED_MASS_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.UNSOLICITED_MASS_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        unsolicitedMassSearchFilterResult: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.UNSOLICITED_MASS_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        unsolicitedMassSearchFilterResult: [],
        searchLoader: false,
      };
    case types.SEASON_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SEASON_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        seasonSearchFilterResult: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SEASON_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        seasonSearchFilterResult: [],
        searchLoader: false,
      };
    case types.BRAND_COMMENT_SAVER_LOADING:
      return {
        ...state,
        loading: true,
        brandCommentLoader: true,
      };
    case types.BRAND_COMMENT_SAVER_SUCCESS:
      return {
        ...state,
        brandComment: action.payload,
        loading: false,
        brandCommentLoader: false,
      };
    case types.BRAND_COMMENT_SAVER_FAIL:
      return {
        ...state,
        loading: false,
        brandCommentLoader: false,
        brandComment: [],
      };

    case types.BRAND_STATUS_SAVER_LOADING:
      return {
        ...state,
        loading: true,
        brandStatusSaverLoader: true,
      };
    case types.BRAND_STATUS_SAVER_SUCCESS:
      return {
        ...state,
        statusSavedData: action.payload,
        loading: false,
        brandStatusSaverLoader: false,
      };
    case types.BRAND_STATUS_SAVER_FAIL:
      return {
        ...state,
        loading: false,
        brandStatusSaverLoader: false,
        statusSavedData: [],
      };

    case types.DESIGN_STATUS_SAVER_LOADING:
      return {
        ...state,
        loading: true,
        designStatusSaverLoader: true,
      };
    case types.DESIGN_STATUS_SAVER_SUCCESS:
      return {
        ...state,
        statusSavedData: action.payload,
        loading: false,
        designStatusSaverLoader: false,
      };
    case types.DESIGN_STATUS_SAVER_FAIL:
      return {
        ...state,
        loading: false,
        statusSavedData: [],
        designStatusSaverLoader: false,
      };

    case types.SRT_DATA_SAVE_LOADING:
      return {
        ...state,
        loading: true,
        statusSaverLoader: true,
      };
    case types.SRT_DATA_SAVE_SUCCESS:
      return {
        ...state,
        srtSaveAllData: action.payload,
        loading: false,
        statusSaverLoader: false,
      };
    case types.SRT_DATA_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        statusSaverLoader: false,
        srtSaveAllData: [],
      };
    case types.SRT_EXPORT_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_EXPORT_SUCCESS:
      return {
        ...state,
        srtExportData: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        srtExportData: [],
        searchLoader: false,
      };
    case types.SRT_EXPORT_DATA:
      return {
        ...state,
        srtExportArrayData: action.payload,
        loading: false,
      };
    case types.SRT_REPORT_GENERATION_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_REPORT_GENERATION_SUCCESS:
      return {
        ...state,
        ReportGeneration: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_REPORT_GENERATION_FAIL:
      return {
        ...state,
        loading: false,
        searchLoader: false,
        ReportGeneration: [],
      };
    case types.SRT_COMMENT_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SRT_COMMENT_UPDATE_SUCCESS:
      return {
        ...state,
        srtCommentUpdate: action.payload,
        loading: false,
      };
    case types.SRT_COMMENT_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        srtCommentUpdate: {},
      };
    case types.SRT_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        srtSearchData: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        searchLoader: false,
        srtSearchData: [],
      };
    case types.SRT_SEASON_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_SEASON_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        srtSeasonSearch: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_SEASON_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        searchLoader: false,
        srtSeasonSearch: [],
      };
    case types.SRT_BULK_SAVE_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_BULK_SAVE_SUCCESS:
      return {
        ...state,
        srtBulkSaveResponse: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_BULK_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        searchLoader: false,
        srtUnsolicitedSamples: [],
      };
      case types.GET_SRT_UNSOLICITED_LIST_LOADING:
        return {
          ...state,
          loading: true,
          searchLoader: true,
          srtRefreshSpinner: true,
        };
      case types.GET_SRT_UNSOLICITED_LIST_SUCCESS:
        return {
          ...state,
          srtUnsolicitedList: action.payload,
          loading: false,
          searchLoader: false,
          srtRefreshSpinner: false,
        };
      case types.GET_SRT_UNSOLICITED_LIST_FAIL:
        return {
          ...state,
          loading: false,
          srtUnsolicitedList: [],
          searchLoader: false,
          srtRefreshSpinner: false,
        };
    case types.SRT_UNSOLICITED_BULK_UPLOAD_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_UNSOLICITED_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        srtUnsolicitedBulkUpload: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_UNSOLICITED_BULK_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        searchLoader: false,
        srtUnsolicitedBulkUpload: [],
      };
      case types.SRT_UNSOLICITED_EXPORT_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_UNSOLICITED_EXPORT_SUCCESS:
      return {
        ...state,
        srtUnsolicitedExportData: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_UNSOLICITED_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        srtUnsolicitedExportData: [],
        searchLoader: false,
      };
      case types.SRT_UNSOLICITED_EDIT_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRT_UNSOLICITED_EDIT_SUCCESS:
      return {
        ...state,
        srtUnsolicitedEdits: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRT_UNSOLICITED_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        srtUnsolicitedEdits: [],
        searchLoader: false,
      };
    default:
      return state;
  }
};
