import {
  CloseOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Radio, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import NewReqIcon from "../../images/add-new-srf.png";
import ChatLogo from "../../images/chat.png";
import CheckOne from "../../images/check-one.png";
import EditIcon from "../../images/edit-icon.png";
import { default as Select } from "../../UIComponents/DropDown/DropDown";
import {
  resetSrfFields,
  srfDeleteColorCode,
  srfDisableToggle,
  srfExFactoryDate,
  srfListEdit,
  srfRowExport,
  srfSendSampleRequest,
  srfTableListData,
  srfTableSaveData,
} from "../../Redux/actions/Srf/Srf";
import CommonButton from "../../UIComponents/Button/Button";
import { toast } from "../../UIComponents/Toast";
import SrfHeader from "./SrfHeader";
import "./SrfTable.css";
import TableDatePicker from "./TableComp.js/DatePicker";
import DropDown from "./TableComp.js/DropDown";
import Image from "./TableComp.js/Image";
import RequestDatePicker from "./TableComp.js/RequestDatePicker";
import RowSavedData from "./TableComp.js/RowSavedData";
import RuInput from "./TableComp.js/RuInput";
import Notes from "./TableComp.js/Notes";
const { Option } = Select;

const NewTable = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const { TextArea } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [requestOne, setRequestOne] = useState(true);
  const [disableAddBtn, setDisableAddBtn] = useState(true);
  const [rowIndexValue, setRowIndexValue] = useState("");
  const [ru, setRU] = useState();
  const [s, setS] = useState(0);
  const [m, setM] = useState(0);
  const [l, setL] = useState(0);
  const [b, setB] = useState(0);
  const [g, setG] = useState(0);
  const [x, setX] = useState(0);
  const [ys, setYs] = useState(0);
  const [ym, setYm] = useState(0);
  const [yl, setYl] = useState(0);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [salesSampleValue, setSalesSampleValue] = useState();
  const [radioButtonValue, setRadioButtonValue] = useState();
  const [radioEmailButtonValue, setRadioEmailButtonValue] = useState();
  const [radioQavButtonValue, setRadioQavButtonValue] = useState();
  const srfScrollPosition = localStorage.getItem("srfAutoScroll");
  const srfTableList = useSelector((state) => state?.Srf?.getSrfTableList);
  const srfLoaderForList = useSelector(
    (state) => state?.Srf?.srfTableListLoader
  );
  const rowReqData = useSelector(
    (state) => state?.Dashboard?.dashboardSingleRowData
  );
  const recievedRes = useSelector((state) => state?.Srf?.srfSaveData?.info);
  const exFactoryDateForApi = useSelector(
    (state) => state?.Srf?.srfExfactoryDateVar
  );

  const userNameMicrosoft = useSelector(
    (state) => state?.Profile?.graphCompleteData?.displayName
  );

  console.log("userNameMicrosoft",userNameMicrosoft);
  
  const reqSectionToggle = useSelector((state) => state.Srf.requestSectionData);
  const srfFieldData = useSelector((state) => state?.Srf?.srfFieldsReset);
  const srfDashboardData = JSON.parse(localStorage.getItem("DashboardData"));
  const srfPathName = localStorage.getItem("srfPath");
  console.log("srfTableList", srfTableList);
  console.log("srfFieldData", srfFieldData);

  const path = location.pathname;
  const prefix = "/sample-request-form/";
  const params = path.slice(prefix.length).split("/");
  const hasParams = params.length > 0;

  useEffect(() => {
    setEditingKey("");
    const paramsObject = {};
    for (let i = 0; i < params.length; i++) {
      paramsObject[`param${i + 1}`] = params[i];
    }
    if (hasParams && path.includes(prefix)) {
      dispatch(
        srfTableListData(
          paramsObject?.param1,
          paramsObject?.param2,
          paramsObject?.param3,
          paramsObject?.param4
        )
      );
    } else if (srfDashboardData) {
      dispatch(
        srfTableListData(
          srfDashboardData?.brand,
          srfDashboardData?.style,
          srfDashboardData?.factory,
          srfDashboardData?.season
        )
      );
    } else {
      dispatch(srfTableListData("", "", "", ""));
    }
  }, []);

  const fileRef = useRef();
  const [dataSource, setDataSource] = useState([]);
  const [dataFromSrfRow, setDataFromSrfRow] = useState();
  const [rowDataForApi, setRowDataForApi] = useState("");
  const [isInputFocused, setIsInputFocused] = useState();
  const [onEditClick, setOnEditClick] = useState(false);
  const [salesSampleStatus, setSalesSampleStatus] = useState();
  const [tpStatus, setTpStatus] = useState();
  const [emailStatus, setEmailStatus] = useState();
  const [textNote, setTextNote] = useState("");
  const [saveToggle, setSaveToggle] = useState(false);
  const [requestNumberForAdd, setRequestNumberForAdd] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const srfSearchData = useSelector((state) => state?.Srf?.srfSearchData);
  const srfSearchLoader = useSelector((state) => state?.Srf?.searchLoader);
  const [arrayForRow, setArrayForRow] = useState({});
  const [sampleDataFiltered, setSampleDataFiltered] = useState([]);
  const [filteredRequestNo, setFilteredRequestNo] = useState([]);
  const [filteredColorCode, setFilteredColorCode] = useState([]);
  const [selectedFilteredColor, setSelectedFilteredColor] = useState();
  const [colorCodeForSampleRequest, setColorCodesForSampleRequest] = useState(
    []
  );
  const [selectedFilteredDataList, setSelectedFilteredDataList] = useState([]);
  const [dataForSampleTable, setDataForSampleTable] = useState([]);
  const [requestValue, setRequestValue] = useState();
  const [previousDataSource, setPreviousDataSource] = useState([]);

  //scroll code
  // const [scrollPositionSrf, setScrollPositionSrf] = useState({ x: 0, y: 0 });

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentPositionSrf = {
  //       x: window.scrollX,
  //       y: window.scrollY,
  //     };
  //     setScrollPositionSrf(currentPositionSrf);
  //     localStorage.setItem(
  //       "scrollYaxisofSrf",
  //       JSON.stringify(currentPositionSrf)
  //     );
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   // Cleanup function to remove event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scrollPositionSrf]);
  // const localStorageYaxisData = localStorage.getItem("scrollYaxisofSrf");
  // console.log("scrollPositionSrfscrollPositionSrf", localStorageYaxisData);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (localStorageYaxisData) {
  //       const { x, y } = JSON.parse(localStorageYaxisData);
  //       window.scrollTo(x, y);
  //       console.log("useeffect is working");
  //     }
  //   }, 3000);
  // }, [srfTableList]);

  const dateFormat = "MM/DD/YYYY";
  const isToday = dayjs().format(dateFormat);

  const initialData = () => {
    if (srfSearchData && srfSearchData.length > 0) {
      return srfSearchData;
    } else if (srfTableList) {
      return srfTableList;
    } else return [];
  };
  console.log("selectedFilteredDataList", selectedFilteredDataList);
  console.log("initialData", srfTableList, srfSearchData);
  const onSalesSampleChange = (e) => {
    setSalesSampleStatus(e.target.value);
    setSalesSampleValue(e.target.value);
  };
  const onTpStatusChange = (e) => {
    setTpStatus(e.target.value);
    setRadioButtonValue(e.target.value);
  };
  const onQavStatusChange = (e) => {
    setRadioQavButtonValue(e.target.value);
  };
  const onEmailStatusChange = (e) => {
    setEmailStatus(e.target.value);
    setRadioEmailButtonValue(e.target.value);
  };
  const onTextNoteChange = (e) => {
    setTextNote(e.target.value);
    localStorage.setItem("SampleNotes", e.target.value);
    setTextAreaValue(e.target.value);
  };
  const SampleSendNotes = localStorage.getItem("SampleNotes");
  const showModal = () => {
    if (salesSampleStatus === "" || salesSampleStatus === null || salesSampleStatus === undefined) {
      toast.error("! Please select the Sales Sample Status");
    }
    else if (tpStatus === "" || tpStatus === null || tpStatus === undefined) {
      toast.error("! Please select the Techpack Status");
    }
    else if (emailStatus === "" || emailStatus === null || emailStatus === undefined) {
      toast.error("! Please select the Email to Send");
    }
    else if (radioQavButtonValue === "" || radioQavButtonValue === null || radioQavButtonValue === undefined) {
      toast.error("! Please select the QAV");
    }
    
    else {
      setIsModalOpen(true);
    }
  };

  const [dataFiltered, setDataFiltered] = useState({});
  const dataFilteredForSample = localStorage.getItem("filteredDataForSample");
  console.log("dataFilteredForSample", dataFilteredForSample);

  useEffect(() => {
    if (dataFilteredForSample !== undefined) {
      setDataFiltered(JSON.parse(dataFilteredForSample));
    }
  }, [dataFilteredForSample]);

  console.log("dataFiltered", dataFiltered);
  console.log("salesSampleStatus",typeof `${salesSampleStatus}`);
  
  let formData = new FormData();
  formData.append("style", dataFiltered?.style);
  if (file && Array.isArray(file)) {
    file?.map((item) => {
      formData.append("files", item ? item : "");
    });
  }
  formData.append("request_notes", SampleSendNotes);
  formData.append("season", dataFiltered?.season);
  formData.append("color_code", colorCodeForSampleRequest);
  formData.append("request_no", selectedFilteredColor);
  formData.append("brand", dataFiltered?.brandForApi);
  formData.append("sale_sample", `${salesSampleStatus}`);
  formData.append("tpstatus", tpStatus);
  formData.append("send_email", emailStatus);
  formData.append("attach_qav", radioQavButtonValue);
  formData.append("factory", dataFiltered?.factory);
  formData.append("size", "");
  formData.append("username", userNameMicrosoft);

  const handleOk = (e) => {
    if (salesSampleStatus === "" || salesSampleStatus === null || salesSampleStatus === undefined) {
      toast.error("Please select the Sales Sample");
    } 
    else if (tpStatus === "" || tpStatus === null || tpStatus === undefined) {
      toast.error("Please select the TechPack");
    } 
    else if (emailStatus === "" || emailStatus === null || emailStatus === undefined) {
      toast.error("Please select the Email to send");
    } 
    else if (radioQavButtonValue === "" || radioQavButtonValue === null || radioQavButtonValue === undefined) {
      toast.error("Please select the QAV");
    } 
    else {
      e.preventDefault();
      setIsModalOpen(false);
      dispatch(srfSendSampleRequest(formData, dataFiltered));
      setFilteredColorCode([]);
      setRequestValue();
      setSalesSampleValue(null);
      setRadioButtonValue(null);
      setRadioEmailButtonValue(null);
      setTextAreaValue("");
      setFile([]);
      setFileName([]);
      setSalesSampleStatus("");
      setTpStatus("");
      setEmailStatus("");
      setRadioQavButtonValue("");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const arrayData = initialData()
    ?.map((item, index) => {
      console.log("here is data", item, index);
      const itemData = item?.request_details?.map((reqData) => {
        return {
          style_size: item?.style_size,
          color_code: item?.color_code,
          brandForApi: item?.brand,
          season: item?.season,
          style: item?.style,
          seasonId: item?.season_id,
          factory: item?.factory,
          user: reqData?.user,
          id: index,
          ...reqData,
        };
      });
      return itemData;
    })
    .flat();

  useEffect(() => {
    const dataForSampleRequest = arrayData?.filter(
      (item) => item?.saved === "1" && item?.request_status === "0"
    );
    console.log("dataForSampleRequest", dataForSampleRequest);
    setSelectedFilteredDataList([...new Set(dataForSampleRequest)]);
    const reqNoList = dataForSampleRequest?.map((item) => item?.request_no);
    setFilteredRequestNo([...new Set(reqNoList)]);

    const filteredRequestNumber = [...new Set(reqNoList)];
    console.log("filteredRequestNumber", filteredRequestNumber);

    const requestNumbersOfArray = arrayData?.map((item) => item?.color_code);
    const colorsWithFilter = [...new Set(requestNumbersOfArray)];

    function checkRequestNo(data, colorCode) {
      return data.some(
        (item) =>
          item.color_code === colorCode &&
          item.request_no === 10 &&
          item.request_status === "1"
      );
    }

    // Example usage
    const colorCodes = colorsWithFilter; // List of color codes to check
    const results = {};

    for (let i = 0; i < colorCodes.length; i++) {
      results[colorCodes[i]] = checkRequestNo(arrayData, colorCodes[i]);
    }

    const variable = Object.values(results).every((value) => value);
    dispatch(srfDisableToggle(variable));

    const filteredData = {};

    arrayData.forEach((item) => {
      if (!filteredData[item.color_code]) {
        filteredData[item.color_code] = {};
      }

      if (!filteredData[item.color_code][item.style_size]) {
        filteredData[item.color_code][item.style_size] = { highest_req_no: 0 };
      }

      if (
        item.request_no >
        filteredData[item.color_code][item.style_size].highest_req_no
      ) {
        filteredData[item.color_code][item.style_size] = {
          ...item,
          highest_req_no: item.request_no,
        };
      }
    });

    arrayData.forEach((item) => {
      const { color_code, style_size, request_no } = item;
      const highestReqNo = filteredData[color_code][style_size].highest_req_no;
      item.is_highest = request_no === highestReqNo;
    });

    const mappedData = arrayData?.map((item, index) => {
      console.log("here is data:Ss", item, index);
      return {
        key: index,
        empty: "",
        notes: (
          <Notes note={item?.request_notes} />
        ),
        request: (
          <p
            className={
              item?.request_no === 1
                ? "request-one"
                : item?.request_no === 2
                ? "request-two"
                : item?.request_no === 3
                ? "request-three"
                : item?.request_no === 4
                ? "request-four"
                : item?.request_no === 5
                ? "request-five"
                : item?.request_no === 6
                ? "request-six"
                : item?.request_no === 7
                ? "request-seven"
                : item?.request_no === 8
                ? "request-eight"
                : item?.request_no === 9
                ? "request-nine"
                : item?.request_no === 10
                ? "request-ten"
                : "request-one"
            }
          >
            {item?.request_no}
          </p>
        ),

        date: "",
        requesteddate: "",
        style: arrayData[index],
        ru: "",
        s: "",
        m: "",
        l: "",
        b: "",
        x: "",
        g: "",
        ys: "",
        ym: "",
        yl: "",
        brand: (
          <Image
            className="srf-comment-img"
            user={item?.user}
            src={ChatLogo}
            commentRecieved={item?.brand_cmt}
          />
        ),
        design: (
          <Image
            className="srf-comment-img"
            user={item?.user}
            src={ChatLogo}
            commentRecieved={item?.design_cmt}
          />
        ),
        pd: (
          <Image
            className="srf-comment-img"
            user={item?.user}
            src={ChatLogo}
            commentRecieved={item?.pd_cmt}
          />
        ),
        qa: (
          <Image
            className="srf-comment-img"
            user={item?.user}
            src={ChatLogo}
            commentRecieved={item?.qa_cmt}
          />
        ),
      };
    });

    setDataSource(mappedData.reverse());
  }, [srfTableList, srfSearchData]);

  useEffect(() => {
    if (filteredRequestNo.length === 1) {
      setSelectedFilteredColor(filteredRequestNo[0]);
      const selectedArray = [];
      console.log("inside condition");
      const filteredColor = selectedFilteredDataList?.filter(
        (item) => item.request_no === filteredRequestNo[0]
      );
      setDataForSampleTable(filteredColor);
      console.log("filteredColor", filteredColor);
      filteredColor?.map((item) => selectedArray?.push(item?.color_code));
      const filteredColorCodes = filteredColor?.map((item) => item?.color_code);
      setColorCodesForSampleRequest(filteredColorCodes);
      if (filteredRequestNo.length === 1) {
        console.log("here ia called");
        localStorage.setItem(
          "filteredDataForSample",
          JSON.stringify(filteredColor[0])
        );
      } else {
        localStorage.setItem("filteredDataForSample", JSON.stringify({}));
      }
      setFilteredColorCode(selectedArray);
    }
  }, [filteredRequestNo]);

  const [tableData, setTableData] = useState(dataSource);

  const onRuChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, RU: e.target.value }));
    setIsInputFocused("ruChange");
    setRU(e.target.value);
  };
  const onRuFocus = () => {
    setIsInputFocused("ruChange");
  };
  const onSdropdownFocus = (value) => {
    setIsInputFocused(value);
  };
  const onMdropdownFocus = () => {
    setIsInputFocused("mChange");
  };
  const onLdropdownFocus = () => {
    setIsInputFocused("lChange");
  };
  const onBdropdownFocus = () => {
    setIsInputFocused("bChange");
  };
  const onXdropdownFocus = () => {
    setIsInputFocused("xChange");
  };
  const onGdropdownFocus = () => {
    setIsInputFocused("gChange");
  };
  const onYSdropdownFocus = () => {
    setIsInputFocused("ysChange");
  };
  const onYMdropdownFocus = () => {
    setIsInputFocused("ymChange");
  };
  const onYLdropdownFocus = () => {
    setIsInputFocused("ylChange");
  };

  console.log("srfFieldData", srfFieldData);

  const onSChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, S: e.target.value }));
    setS(e.target.value);
  };
  const onMChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, M: e.target.value }));
    setM(e.target.value);
  };
  const onLChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, L: e.target.value }));
    setL(e.target.value);
  };
  const onBChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, B: e.target.value }));
    setB(e.target.value);
  };
  const onXChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, X: e.target.value }));
    setX(e.target.value);
  };
  const onGChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, G: e.target.value }));
    setG(e.target.value);
  };

  const onYsChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, YS: e.target.value }));
    setYs(e.target.value);
  };

  const onYmChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, YM: e.target.value }));
    setYm(e.target.value);
  };

  const onYlChange = (e) => {
    dispatch(resetSrfFields({ ...srfFieldData, YL: e.target.value }));
    setYl(e.target.value);
  };

  const onDeleteClick = (item, index) => {
    setFileName(fileName.filter((item, i) => i !== index));
    setFile(file.filter((item, i) => i !== index));
  };

  const onSearchS = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, S: value }));
  };

  const onSearchM = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, M: value }));
  };

  const onSearchL = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, L: value }));
  };

  const onSearchB = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, B: value }));
  };

  const onSearchX = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, X: value }));
  };

  const onSearchG = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, G: value }));
  };

  const onSearchYS = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, YS: value }));
  };

  const onSearchYM = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, YM: value }));
  };

  const onSearchYL = (value) => {
    dispatch(resetSrfFields({ ...srfFieldData, YL: value }));
  };

  const isEditing = (record) => record.key === editingKey;

  // false
  const save = (record, key) => {
    console.log("forsavedata", record?.style);
    localStorage.setItem(
      "filteredDataForSample",
      JSON.stringify(record?.style)
    );
    setArrayForRow(record?.style);

    const dateFormat = "MM/DD/YYYY";
    const createdDate = dayjs(record?.style?.created_date).format(dateFormat);
    const twoWeeksLater = dayjs(createdDate).add(2, "week").format(dateFormat);

    let result = true;
    let nonEmptyCount = 0;

    const keysToCheck = ["L", "G", "M", "X", "YS", "YM", "YL", "B", "S"];

    for (const key of keysToCheck) {
      const value = srfFieldData[key];

      if (value != "") {
        result = false;
        break;
      }

      if (value != "") {
        nonEmptyCount++;
      }
    }

    if (nonEmptyCount > 1) {
      result = false;
    }

    console.log("resultresult", result);
    const allKeysValid = keysToCheck.every((key) => srfFieldData[key] === "");

    if (srfFieldData?.RU === null || srfFieldData?.RU === "") {
      toast.error("Please select the quantity!");
    } else if (allKeysValid) {
      toast.error("Please select the quantity!");
    }
    // else if (result) {
    //   toast.error("Please select the quantity!");
    // }
    else {
      dispatch(
        srfTableSaveData(
          srfFieldData?.style,
          srfFieldData?.season,
          srfFieldData?.seasonId,
          srfFieldData?.brandForApi,
          srfFieldData?.factory,
          srfFieldData?.color_code,
          srfFieldData?.request_no,
          srfFieldData?.req_exfactory ? srfFieldData?.req_exfactory : "",
          srfFieldData?.RU,
          srfFieldData?.S,
          srfFieldData?.M,
          srfFieldData?.L,
          srfFieldData?.B,
          srfFieldData?.G,
          srfFieldData?.X,
          srfFieldData?.YS,
          srfFieldData?.YM,
          srfFieldData?.YL,
          srfDashboardData
        )
      );
      setFilteredColorCode([]);
      setRequestValue();
      const newData = [...tableData];
      const targetIndex = newData.findIndex((item) => key === item.key);
      if (targetIndex > -1 || targetIndex == -1) {
        const item = newData[targetIndex];
        newData.splice(targetIndex, 1, { ...item });
        setTableData(newData);
        setEditingKey("");
      }
      setRequestNumberForAdd("");
      setIsInputFocused("");
      setRequestOne(false);
      localStorage.setItem("RequestSend", true);
      // dispatch(srfRequestData(true))
    }
  };

  const requestSendToggle = localStorage.getItem("RequestSend");

  // true
  const editForGettingData = (record, key) => {
    setEditingKey(key);
    dispatch(resetSrfFields(record?.style));
  };

  const cancelButton = (record, key) => {
    if (typeof key === "string") {
      const RemovingRow = dataSource?.filter((item) => item?.key !== key);

      const color_code = record?.style?.color_code;
      const request_no = record?.style?.request_no - 1;

      const modifiedData = RemovingRow.map((item) => {
        if (
          item?.style?.color_code === color_code &&
          item?.style?.request_no === request_no
        ) {
          return {
            ...item,
            style: {
              ...item.style,
              is_highest: true,
            },
          };
        }
        return item;
      });

      console.log("modifiedData", modifiedData);
      setDataSource(modifiedData);
    }
    const newData = [...tableData];
    const targetIndex = newData.findIndex((item) => key === item.key);
    if (targetIndex > -1 || targetIndex == -1) {
      const item = newData[targetIndex];
      newData.splice(targetIndex, 1, { ...item });
      setTableData(newData);
      setEditingKey("");
    }
  };

  console.log("daatascoucei", dataSource);

  const saveAfterEdit = (record, key) => {
    console.log("foreditaftersave", record?.style);
    localStorage.setItem(
      "filteredDataForSample",
      JSON.stringify(record?.style)
    );
    setArrayForRow(record?.style);

    const dateFormat = "MM/DD/YYYY";
    const createdDate = dayjs(record?.style?.created_date).format(dateFormat);
    const twoWeeksLater = dayjs(createdDate).add(2, "week").format(dateFormat);

    let result = true;
    let nonEmptyCount = 0;

    const keysToCheck = ["L", "G", "M", "X", "YS", "YM", "YL", "B", "S"];

    for (const key of keysToCheck) {
      const value = srfFieldData[key];

      if (value != "") {
        result = false;
        break;
      }

      if (value != "") {
        nonEmptyCount++;
      }
    }

    if (nonEmptyCount > 1) {
      result = false;
    }

    console.log("resultresult", result);
    const allKeysValid = keysToCheck.every(
      (key) =>
        // srfFieldData[key] === "0" ||
        srfFieldData[key] === ""
    );

    if (
      srfFieldData?.RU == null ||
      srfFieldData?.RU === ""
      // srfFieldData?.RU === "0"
    ) {
      toast.error("Please select the quantity!");
    } else if (allKeysValid) {
      toast.error("Please select the quantity!");
    }
    // else if (result) {
    //   toast.error("Please select the quantity!");
    // }
    else {
      dispatch(
        srfListEdit(
          srfFieldData?.style,
          srfFieldData?.season,
          srfFieldData?.seasonId,
          srfFieldData?.brandForApi,
          srfFieldData?.factory,
          srfFieldData?.color_code,
          srfFieldData?.request_no,
          srfFieldData?.RU,
          srfFieldData?.S,
          srfFieldData?.M,
          srfFieldData?.L,
          srfFieldData?.B,
          srfFieldData?.G,
          srfFieldData?.X,
          srfFieldData?.YS,
          srfFieldData?.YM,
          srfFieldData?.YL,
          srfFieldData?.req_exfactory ? srfFieldData?.req_exfactory : "",
          srfDashboardData
        )
      );
      setFilteredColorCode([]);
      setRequestValue();
      setRU("");
      setL("");
      setB("");
      setM("");
      setX("");
      setS("");
      setG("");
      setYs("");
      setYm("");
      setYl("");
      // setSaveToggle(false)
      setOnEditClick(true);
      setRequestOne(false);
      setIsInputFocused("");
      // dispatch(srfRequestData(true))
      localStorage.setItem("RequestSend", true);
      const newData = [...tableData];
      const targetIndex = newData.findIndex((item) => key === item.key);
      if (targetIndex > -1 || targetIndex == -1) {
        const item = newData[targetIndex];
        newData.splice(targetIndex, 1, { ...item });
        setTableData(newData);
        setEditingKey("");
      }
    }
  };

  const handleAdd = (record, index, arrayData) => {
    setPreviousDataSource(dataSource);
    console.log("handleadd log", record?.style);
    setRowDataForApi(arrayData);
    // const newData = [...dataSource];
    const newData = dataSource.slice();
    console.log("newData", newData);
    const singleData = dataSource?.filter((item) => item.key === record.key);
    const dateFormat = "MM/DD/YYYY";
    const createdDate = dayjs(record?.style?.created_date).format(dateFormat);
    const twoWeeksLater = dayjs(createdDate).add(2, "week").format(dateFormat);
    if (record) {
      record.style.is_highest = false;
    }

    const recordObj = record?.style;

    const keysWithValue = ["G", "M", "S", "X", "YS", "YL", "YM", "B", "L"];
    const styleObj = {};
    for (const key of keysWithValue) {
      if (recordObj.hasOwnProperty(key) && recordObj[key]) {
        styleObj[key] = 0;
      }
    }
    styleObj["RU"] = null;

    const newStyle = {
      ...recordObj,
      request_status: "0",
      request_no: arrayData.request_no + 1,
      is_highest: true,
      req_exfactory: null,
      sample_sent: "",
      saved: "0",
      ...styleObj, // Include the updated style object with null values
    };

    newData.splice(index, 0, {
      ...record,
      key: `${dataSource.length + 1}`,
      empty: "",
      requesteddate: <RequestDatePicker reqStatus={"0"} reqDate={""} />,
      style: newStyle,
      date: <p className="todays-date">{isToday}</p>,
      request: (
        <p
          className={
            singleData[0].request?.props?.children === 1
              ? "request-two"
              : singleData[0].request?.props?.children === 2
              ? "request-three"
              : singleData[0].request?.props?.children === 3
              ? "request-four"
              : singleData[0].request?.props?.children === 4
              ? "request-five"
              : singleData[0].request?.props?.children === 5
              ? "request-six"
              : singleData[0].request?.props?.children === 6
              ? "request-seven"
              : singleData[0].request?.props?.children === 7
              ? "request-eight"
              : singleData[0].request?.props?.children === 8
              ? "request-nine"
              : singleData[0].request?.props?.children === 9
              ? "request-ten"
              : "request-one"
          }
        >
          {parseInt(singleData[0].request?.props?.children) + 1}
        </p>
      ),
      brand: <Image src={ChatLogo} commentRecieved={""} />,
      design: <Image src={ChatLogo} commentRecieved={""} />,
      pd: <Image src={ChatLogo} commentRecieved={""} />,
      qa: <Image src={ChatLogo} commentRecieved={""} />,
    });
    console.log("added data", newData);
    const requestLength = singleData[0]?.request?.props?.children;
    setRequestNumberForAdd(requestLength + 1);
    if (requestLength <= 9) {
      setDataSource(newData);
    } else {
      setDisableAddBtn(false);
    }
  };

  const onDeleteButtonClick = (record, index, arrayData) => {
    setIsDeleteModalOpen(true);
    console.log("record, index, arrayData", record, index, arrayData);
    localStorage.setItem(
      "dataForDelete",
      JSON.stringify({
        style: record?.style?.style,
        color: record?.style?.color_code,
        factory: record?.style?.factory,
        requestNumber: record?.style?.request_no,
      })
    );
  };

  const dataForDeleteFromLocal = JSON.parse(
    localStorage.getItem("dataForDelete")
  );

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
    dispatch(
      srfDeleteColorCode(
        {
          style: dataForDeleteFromLocal?.style,
          color: dataForDeleteFromLocal?.color,
          factory: dataForDeleteFromLocal?.factory,
          reqno: dataForDeleteFromLocal?.requestNumber,
        },
        srfDashboardData
      )
    );
  };

  const onRequestClick = (record, rowIndex, arrayData) => {
    setRowDataForApi(record?.style);
    setDataFromSrfRow(record);
    dispatch(srfRowExport(record?.style));
  };
  console.log("filesadded", file);
  const changeFileHandler = (e) => {
    setFile([...file, e.target.files[0]]);
    setFileName([...fileName, e.target.files[0].name]);
  };

  const onRequestNoChangeHandle = (value) => {
    console.log("valuevalue", value);
    setRequestValue(value);
    setSelectedFilteredColor(value);
    const selectedArray = [];
    const filteredColor = selectedFilteredDataList?.filter((item) =>
      value.includes(item.request_no)
    );
    setDataForSampleTable(filteredColor);
    console.log("filteredColor", filteredColor);
    filteredColor?.map((item) => selectedArray?.push(item?.color_code));
    console.log("filteredDataForSample", filteredColor);
    const filteredColorCodes = filteredColor?.map((item) => item?.color_code);
    setColorCodesForSampleRequest(filteredColorCodes);
    console.log("filteredColorCodes", filteredColorCodes);

    if (value.length > 0) {
      localStorage.setItem(
        "filteredDataForSample",
        JSON.stringify(filteredColor[0])
      );
    } else {
      console.log("inside values");
      localStorage.setItem("filteredDataForSample", JSON.stringify({}));
    }

    setFilteredColorCode(selectedArray);
    console.log("selectedArray", filteredColor);
  };

  const columns = [
    {
      title: <p className="remove-color">hi</p>,
      dataIndex: "empty",
      width: "8%",
      render: (text, record) => {
        return (
          <div
            className={
              record?.style?.is_highest == true
                ? "srf-table-vector"
                : "srf-color-code-display"
            }
          >
            <h2 className="srf-table-number">{record?.style?.color_code}</h2>
          </div>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Request",
      dataIndex: "request",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "8%",
      render: (text, record) => {
        if (record?.style?.sample_sent) {
          return <TableDatePicker presentDate={record?.style?.sample_sent} />;
        }
        if (record?.style?.sample_sent == null) {
          return <p>-</p>;
        }
        if (record?.style?.sample_sent == "") {
          return <p>-</p>;
        }
      },
    },
    {
      title: "Req Ex-factory Date",
      dataIndex: "requesteddate",
      width: "10%",
      render: (text, record) => {
        const dateFormat = "MM/DD/YYYY";
        const createdDate = dayjs(record?.style?.created_date).format(
          dateFormat
        );
        const twoWeeksLater = dayjs(createdDate)
          .add(2, "week")
          .format(dateFormat);
        console.log("exfactorycreated", twoWeeksLater);

        const editable = isEditing(record);
        
        if (
          (!editable && record?.style?.req_exfactory == "") ||
          record?.style?.req_exfactory == "null"
        ) {
          return <TableDatePicker presentDate={"-"} />;
        }

        if (!editable && record?.style?.req_exfactory) {
          return <TableDatePicker presentDate={record?.style?.req_exfactory} />;
        }

        if (!editable && record?.style?.req_exfactory == null) {
          return (
            <RequestDatePicker
              createdDate={twoWeeksLater}
              reqStatus={"1"}
              reqDate={""}
            />
          );
        }

        if (editable) {
          return (
            <RequestDatePicker
              createdDate={twoWeeksLater}
              reqStatus={record?.style?.request_status}
              reqDate={""}
            />
          );
        }
      },
    },
    {
      title: <p className="ru-input-highlight">RU</p>,
      dataIndex: "ru",
      width: 10,
      render: (text, record) => {
        const editable = isEditing(record);

        if (!editable && record?.style?.RU) {
          return <RowSavedData resData={record?.style?.RU} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.RU) {
          return (
            <RuInput
              onChange={onRuChange}
              value={srfFieldData?.RU}
              onClick={onRuFocus}
            />
          );
        } else if (editable && record?.style?.RU === 0) {
          return (
            <RuInput
              onChange={onRuChange}
              value={srfFieldData?.RU}
              onClick={onRuFocus}
            />
          );
        } else if (editable && record?.style?.RU === "") {
          return (
            <RuInput
              onChange={onRuChange}
              value={srfFieldData?.RU}
              onClick={onRuFocus}
            />
          );
        } else if (editable && record?.style?.RU === null) {
          return (
            <RuInput
              onChange={onRuChange}
              value={srfFieldData?.RU}
              onClick={onRuFocus}
            />
          );
        }
      },
      width: 50,
    },
    {
      title: (
        <p
          className={
            isInputFocused == "sChange" ? "highlight" : "highlight-focused"
          }
        >
          S
        </p>
      ),
      dataIndex: "s",
      width: 50,
      render: (text, record) => {
        console.log("reocrd of s", record?.style);
        const editable = isEditing(record);

        if (!editable && record?.style?.S) {
          return <RowSavedData resData={record?.style?.S} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.S === 0) {
          return <RowSavedData resData={record?.style?.S} />;
        } else if (!editable && record?.style?.S === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.S === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.S) {
          return (
            <RuInput
              onChange={onSChange}
              value={srfFieldData?.S}
              onClick={onSdropdownFocus}
              disabled={record?.style?.S === ""}
            />
          );
        } else if (editable && record?.style?.S === 0) {
          return (
            <RuInput
              onChange={onSChange}
              value={srfFieldData?.S}
              onClick={onSdropdownFocus}
              disabled={record?.style?.S === ""}
            />
          );
        } else if (editable && record?.style?.S === "") {
          return (
            <RuInput
              onChange={onSChange}
              value={srfFieldData?.S}
              onClick={onSdropdownFocus}
              disabled={record?.style?.S === ""}
            />
          );
        } else if (editable && record?.style?.S === null) {
          return (
            <RuInput
              onChange={onSChange}
              value={srfFieldData?.S}
              onClick={onSdropdownFocus}
              disabled={record?.style?.S === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "mChange" ? "highlight" : "highlight-focused"
          }
        >
          M
        </p>
      ),
      dataIndex: "m",
      render: (text, record) => {
        const editable = isEditing(record);

        if (!editable && record?.style?.M) {
          return <RowSavedData resData={record?.style?.M} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.M === 0) {
          return <RowSavedData resData={record?.style?.M} />;
        } else if (!editable && record?.style?.M === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.M === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.M) {
          return (
            <RuInput
              onChange={onMChange}
              value={srfFieldData?.M}
              onClick={onMdropdownFocus}
              disabled={record?.style?.M === ""}
            />
          );
        } else if (editable && record?.style?.M === 0) {
          return (
            <RuInput
              onChange={onMChange}
              value={srfFieldData?.M}
              onClick={onMdropdownFocus}
              disabled={record?.style?.M === ""}
            />
          );
        } else if (editable && record?.style?.M === "") {
          return (
            <RuInput
              onChange={onMChange}
              value={srfFieldData?.M}
              onClick={onMdropdownFocus}
              disabled={record?.style?.M === ""}
            />
          );
        } else if (editable && record?.style?.M === null) {
          return (
            <RuInput
              onChange={onMChange}
              value={srfFieldData?.M}
              onClick={onMdropdownFocus}
              disabled={record?.style?.M === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "lChange" ? "highlight" : "highlight-focused"
          }
        >
          L
        </p>
      ),
      dataIndex: "l",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.L) {
          return <RowSavedData resData={record?.style?.L} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.L === 0) {
          return <RowSavedData resData={record?.style?.L} />;
        } else if (!editable && record?.style?.L === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.L === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.L) {
          return (
            <RuInput
              onChange={onLChange}
              value={srfFieldData?.L}
              onClick={onLdropdownFocus}
              disabled={record?.style?.L === ""}
            />
          );
        } else if (editable && record?.style?.L === 0) {
          return (
            <RuInput
              onChange={onLChange}
              value={srfFieldData?.L}
              onClick={onLdropdownFocus}
              disabled={record?.style?.L === ""}
            />
          );
        } else if (editable && record?.style?.L === "") {
          return (
            <RuInput
              onChange={onLChange}
              value={srfFieldData?.L}
              onClick={onLdropdownFocus}
              disabled={record?.style?.L === ""}
            />
          );
        } else if (editable && record?.style?.L === null) {
          return (
            <RuInput
              onChange={onLChange}
              value={srfFieldData?.L}
              onClick={onLdropdownFocus}
              disabled={record?.style?.L === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "bChange" ? "highlight" : "highlight-focused"
          }
        >
          B
        </p>
      ),
      dataIndex: "b",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.B) {
          return <RowSavedData resData={record?.style?.B} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.B === 0) {
          return <RowSavedData resData={record?.style?.B} />;
        }
        if (!editable && record?.style?.B === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.B === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.B) {
          return (
            <RuInput
              onChange={onBChange}
              value={srfFieldData?.B}
              onClick={onBdropdownFocus}
              disabled={record?.style?.B === ""}
            />
          );
        } else if (editable && record?.style?.B === 0) {
          return (
            <RuInput
              onChange={onBChange}
              value={srfFieldData?.B}
              onClick={onBdropdownFocus}
              disabled={record?.style?.B === ""}
            />
          );
        } else if (editable && record?.style?.B === "") {
          return (
            <RuInput
              onChange={onBChange}
              value={srfFieldData?.B}
              onClick={onBdropdownFocus}
              disabled={record?.style?.B === ""}
            />
          );
        } else if (editable && record?.style?.B === null) {
          return (
            <RuInput
              onChange={onBChange}
              value={srfFieldData?.B}
              onClick={onBdropdownFocus}
              disabled={record?.style?.B === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "xChange" ? "highlight" : "highlight-focused"
          }
        >
          X
        </p>
      ),
      dataIndex: "x",
      render: (text, record) => {
        console.log("record data", record?.style);

        const editable = isEditing(record);
        if (!editable && record?.style?.X) {
          return <RowSavedData resData={record?.style?.X} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.X === 0) {
          return <RowSavedData resData={record?.style?.X} />;
        } else if (!editable && record?.style?.X === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.X === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.X) {
          return (
            <RuInput
              onChange={onXChange}
              value={srfFieldData?.X}
              onClick={onXdropdownFocus}
              disabled={record?.style?.X === ""}
            />
          );
        } else if (editable && record?.style?.X === 0) {
          return (
            <RuInput
              onChange={onXChange}
              value={srfFieldData?.X}
              onClick={onXdropdownFocus}
              disabled={record?.style?.X === ""}
            />
          );
        } else if (editable && record?.style?.X === "") {
          return (
            <RuInput
              onChange={onXChange}
              value={srfFieldData?.X}
              onClick={onXdropdownFocus}
              disabled={record?.style?.X === ""}
            />
          );
        } else if (editable && record?.style?.X === null) {
          return (
            <RuInput
              onChange={onXChange}
              value={srfFieldData?.X}
              onClick={onXdropdownFocus}
              disabled={record?.style?.X === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "gChange" ? "highlight" : "highlight-focused"
          }
        >
          G
        </p>
      ),
      dataIndex: "g",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.G) {
          return <RowSavedData resData={record?.style?.G} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.G === 0) {
          return <RowSavedData resData={record?.style?.G} />;
        } else if (!editable && record?.style?.G === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.G === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.G) {
          return (
            <RuInput
              onChange={onGChange}
              value={srfFieldData?.G}
              onClick={onGdropdownFocus}
              disabled={record?.style?.G === ""}
            />
          );
        } else if (editable && record?.style?.G === 0) {
          return (
            <RuInput
              onChange={onGChange}
              value={srfFieldData?.G}
              onClick={onGdropdownFocus}
              disabled={record?.style?.G === ""}
            />
          );
        } else if (editable && record?.style?.G === "") {
          return (
            <RuInput
              onChange={onGChange}
              value={srfFieldData?.G}
              onClick={onGdropdownFocus}
              disabled={record?.style?.G === ""}
            />
          );
        } else if (editable && record?.style?.G === null) {
          return (
            <RuInput
              onChange={onGChange}
              value={srfFieldData?.G}
              onClick={onGdropdownFocus}
              disabled={record?.style?.G === ""}
            />
          );
        }
      },
    },

    {
      title: (
        <p
          className={
            isInputFocused == "ysChange" ? "highlight" : "highlight-focused"
          }
        >
          YS
        </p>
      ),
      dataIndex: "ys",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.YS) {
          return <RowSavedData resData={record?.style?.YS} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.YS === 0) {
          return <RowSavedData resData={record?.style?.YS} />;
        } else if (!editable && record?.style?.YS === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.YS === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.YS) {
          return (
            <RuInput
              onChange={onYsChange}
              value={srfFieldData?.YS}
              onClick={onYSdropdownFocus}
              disabled={record?.style?.YS === ""}
            />
          );
        } else if (editable && record?.style?.YS === 0) {
          return (
            <RuInput
              onChange={onYsChange}
              value={srfFieldData?.YS}
              onClick={onYSdropdownFocus}
              disabled={record?.style?.YS === ""}
            />
          );
        } else if (editable && record?.style?.YS === "") {
          return (
            <RuInput
              onChange={onYsChange}
              value={srfFieldData?.YS}
              onClick={onYSdropdownFocus}
              disabled={record?.style?.YS === ""}
            />
          );
        } else if (editable && record?.style?.YS === null) {
          return (
            <RuInput
              onChange={onYsChange}
              value={srfFieldData?.YS}
              onClick={onYSdropdownFocus}
              disabled={record?.style?.YS === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "ymChange" ? "highlight" : "highlight-focused"
          }
        >
          YM
        </p>
      ),
      dataIndex: "ym",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.YM) {
          return <RowSavedData resData={record?.style?.YM} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.YM === 0) {
          return <RowSavedData resData={record?.style?.YM} />;
        } else if (!editable && record?.style?.YM === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.YM === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.YM) {
          return (
            <RuInput
              onChange={onYmChange}
              value={srfFieldData?.YM}
              onClick={onYMdropdownFocus}
              disabled={record?.style?.YM === ""}
            />
          );
        } else if (editable && record?.style?.YM === 0) {
          return (
            <RuInput
              onChange={onYmChange}
              value={srfFieldData?.YM}
              onClick={onYMdropdownFocus}
              disabled={record?.style?.YM === ""}
            />
          );
        } else if (editable && record?.style?.YM === "") {
          return (
            <RuInput
              onChange={onYmChange}
              value={srfFieldData?.YM}
              onClick={onYMdropdownFocus}
              disabled={record?.style?.YM === ""}
            />
          );
        } else if (editable && record?.style?.YM === null) {
          return (
            <RuInput
              onChange={onYmChange}
              value={srfFieldData?.YM}
              onClick={onYMdropdownFocus}
              disabled={record?.style?.YM === ""}
            />
          );
        }
      },
    },
    {
      title: (
        <p
          className={
            isInputFocused == "ylChange" ? "highlight" : "highlight-focused"
          }
        >
          YL
        </p>
      ),
      dataIndex: "yl",
      render: (text, record) => {
        const editable = isEditing(record);
        if (!editable && record?.style?.YL) {
          return <RowSavedData resData={record?.style?.YL} />;
        }

        if (!editable && record?.style?.RU == "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.RU == null) {
          return <p>-</p>;
        }
        if (!editable && record?.style?.YL === 0) {
          return <RowSavedData resData={record?.style?.YL} />;
        } else if (!editable && record?.style?.YL === "") {
          return <p>-</p>;
        } else if (!editable && record?.style?.YL === null) {
          return <p>-</p>;
        }

        if (editable && record?.style?.YL) {
          return (
            <RuInput
              onChange={onYlChange}
              value={srfFieldData?.YL}
              onClick={onYLdropdownFocus}
              disabled={record?.style?.YL === ""}
            />
          );
        } else if (editable && record?.style?.YL === 0) {
          return (
            <RuInput
              onChange={onYlChange}
              value={srfFieldData?.YL}
              onClick={onYLdropdownFocus}
              disabled={record?.style?.YL === ""}
            />
          );
        } else if (editable && record?.style?.YL === "") {
          return (
            <RuInput
              onChange={onYlChange}
              value={srfFieldData?.YL}
              onClick={onYLdropdownFocus}
              disabled={record?.style?.YL === ""}
            />
          );
        } else if (editable && record?.style?.YL === null) {
          return (
            <RuInput
              onChange={onYlChange}
              value={srfFieldData?.YL}
              onClick={onYLdropdownFocus}
              disabled={record?.style?.YL === ""}
            />
          );
        }
      },
    },
    {
      title: "Sample Tracker Comments",
      children: [
        {
          title: "BRAND",
          dataIndex: "brand",
          key: "brand",
        },
        {
          title: "DESIGN",
          dataIndex: "design",
          key: "design",
        },
        {
          title: "PD",
          dataIndex: "pd",
          key: "pd",
        },
        {
          title: "QA",
          dataIndex: "qa",
          key: "qa",
        },
      ],
    },
    {
      title: <p className="remove-color">hi</p>,
      dataIndex: "action",
      width: "7%",
      render: (_, record, index) => {
        const editable = isEditing(record);

        if (
          !editable &&
          record?.style?.saved == "1" &&
          record?.style?.request_status == "1" &&
          record?.style?.is_highest == true &&
          record?.style?.request_no < 10
        ) {
          return (
            <div className="addbutton-and-edit-container">
              <img
                className="add-icon"
                src={NewReqIcon}
                onClick={() => handleAdd(record, index, arrayData[record.key])}
                alt="add"
              />
            </div>
          );
        }

        if (
          !editable &&
          record?.style?.saved == "1" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <div className="addbutton-and-edit-container">
              <img
                className="edit-row-img"
                src={EditIcon}
                onClick={() => editForGettingData(record, record.key)}
                alt="edit"
              />
              <DeleteOutlined
                onClick={() =>
                  onDeleteButtonClick(record, index, arrayData[record.key])
                }
                className="delete-icon"
              />
            </div>
          );
        } else if (
          !editable &&
          record?.style?.saved == "0" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <div className="addbutton-and-edit-container">
              <img
                className="edit-row-img"
                src={EditIcon}
                onClick={() => editForGettingData(record, record.key)}
                alt="edit"
              />
              <DeleteOutlined
                onClick={() =>
                  onDeleteButtonClick(record, index, arrayData[record.key])
                }
                className="delete-icon"
              />
            </div>
          );
        } else if (
          !editable &&
          record?.style?.saved == "0" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <div className="addbutton-and-edit-container">
              <img
                className="edit-row-img"
                src={EditIcon}
                onClick={() => editForGettingData(record, record.key)}
                alt="edit"
              />
              <DeleteOutlined
                onClick={() =>
                  onDeleteButtonClick(record, index, arrayData[record.key])
                }
                className="delete-icon"
              />
            </div>
          );
        }

        if (
          editable &&
          record?.style?.saved == "1" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <>
              <Button
                className={"srf-save"}
                onClick={() => saveAfterEdit(record, record.key)}
              >
                Save
              </Button>
              <Button
                className={"srf-cancel"}
                onClick={() => cancelButton(record, record.key)}
              >
                Cancel
              </Button>
            </>
          );
        }

        if (
          editable &&
          record?.style?.saved == "0" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <>
              <Button
                className={"srf-save"}
                onClick={() => save(record, record.key)}
              >
                Save
              </Button>
              <Button
                className={"srf-cancel"}
                onClick={() => cancelButton(record, record.key)}
              >
                Cancel
              </Button>
            </>
          );
        } else if (
          editable &&
          record?.style?.saved == "0" &&
          record?.style?.request_status == "0"
        ) {
          return (
            <>
              <Button
                className={"srf-save"}
                onClick={() => save(record, record.key)}
              >
                Save
              </Button>
              <Button
                className={"srf-cancel"}
                onClick={() => cancelButton(record, record.key)}
              >
                Cancel
              </Button>
            </>
          );
        }
      },
    },
  ];

  const SampleColumns = [
    {
      title: "Style",
      dataIndex: "style",
      width: 240,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      width: 240,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      width: 240,
    },
    {
      title: "Color",
      dataIndex: "color",
      width: 240,
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 240,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 240,
    },
  ];

  console.log("dataForSampleTable", dataForSampleTable);
  const sizes = {};
  const quantityKeys = ["G", "M", "S", "L", "B", "X", "YM", "YL", "YS"];

  for (const item of dataForSampleTable) {
    item.sizes = [];

    for (const key of quantityKeys) {
      if (item[key]) {
        const quantity = item[key] * item.RU;
        item.sizes.push({ size: key, quantity });
      }
    }
  }

  const SampleData = [];
  {
    dataForSampleTable?.map((item, index) => {
      return SampleData.push({
        key: index,
        style: item?.style,
        brand: item?.brandForApi,
        vendor: item?.factory,
        color: item?.color_code,
        size: item?.sizes?.map((size) => {
          return <p className="popup-quantity">{size?.size}</p>;
        }),
        quantity: item?.sizes?.map((qty) => {
          return <p className="popup-quantity">{qty?.quantity}</p>;
        }),
      });
    });
  }

  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      console.log("scrolled postion", scrollTop);
      localStorage.setItem("srfAutoScroll", scrollTop);
    }
  };

  const handleScrollButton = () => {
    if (srfScrollPosition) {
      window.scrollTo({
        top: containerRef.current.offsetTop,
      });
      containerRef.current.scrollTo({
        top: srfScrollPosition,
      });
    }
  };
  useEffect(() => {
    console.log("scroll position of localstorage", srfScrollPosition);
    handleScrollButton();
  }, [srfTableList]);

  return (
    <div className="srf-table-main">
      {/* <button onClick={handleScrollButton}>scroll</button> */}
      <SrfHeader title="Sample Request Form" />
      {srfLoaderForList ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={srfLoaderForList}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div ref={containerRef} onScroll={handleScroll} className="srf-table">
          <Table
            // pagination={true}
            pagination={{ pageSize: 100 }}
            dataSource={dataSource}
            columns={columns}
            rowClassName="editable-row"
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  onRequestClick(record, rowIndex, arrayData); // click row
                  setRowIndexValue(record?.request?.props?.children);
                },
              };
            }}
          />
          <div className="srf-modal">
            <Modal title="" height="30" width={400} open={isDeleteModalOpen} onCancel={handleDeleteCancel} >
              <div className="srf-modal-content">
                <div className="srf-modal-image-txt">
                  <p className="colon-bold">
                    "Please confirm deletion of Request Number-
                    {dataForDeleteFromLocal?.requestNumber}, Style-
                    {dataForDeleteFromLocal?.style}, Color-
                    {dataForDeleteFromLocal?.color}, Vendor-
                    {dataForDeleteFromLocal?.factory} ?"
                  </p>
                </div>
              </div>
              <div className="srf-buttons">
                <Button onClick={handleDeleteCancel}>No, cancel</Button>
                <Button className="modal-yes-button" onClick={handleDeleteOk}>
                  Yes, confirm
                </Button>
              </div>
            </Modal>
          </div>

          <div className="send-sample-container">
            <p className="req-one-title">Send Sample Requests</p>
            <div>
            {selectedFilteredDataList?.length > 0 ? (
              <div className="send-sample-main-container">
              <div className="send-sample-headings-container">
                <div className="send-sample-headings">
                  <p className="heading-names">Request #</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Color Code</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Is It a Sales Sample?</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Attach Tech Pack?</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Attach QAV?</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Send this SRF to Vendor?</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings-files">
                  <p className="heading-names">Attach Other Files?</p>
                  <p>:</p>
                </div>
                <div className="send-sample-headings">
                  <p className="heading-names">Request Notes</p>
                  <p>:</p>
                </div>
              </div>


              <div className="send-sample-content-container">
                <div className="send-sample-content">
                  {filteredRequestNo.length === 1 ? (
                    <p>{filteredRequestNo[0]}</p>
                  ) : (
                    <Select
                      className="request-number-select-srf"
                      mode={"multiple"}
                      // placeholder={""}
                      data={filteredRequestNo}
                      onChange={onRequestNoChangeHandle}
                    ></Select>
                  )}
                </div>
                <div className="send-sample-content">
                  <div className="bottom-sec-colorcode">
                  {filteredColorCode?.map((item) => {
                    return (
                      <p className="request-one-color-code">{item}</p>
                    );
                  })}
                  </div>
                </div>

                <div className="send-sample-content">
                <span>
                  <Radio.Group
                    onChange={onSalesSampleChange}
                    value={salesSampleValue}
                  >
                    <Radio name="1" value={1}>
                      Yes
                    </Radio>
                    <Radio name="0" value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
                <div className="send-sample-content">
                <span>
                  <Radio.Group
                    onChange={onTpStatusChange}
                    value={radioButtonValue}
                  >
                    <Radio name="1" value={1}>
                      Yes
                    </Radio>
                    <Radio name="0" value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="send-sample-content">
                <span>
                  <Radio.Group
                    onChange={onQavStatusChange}
                    value={radioQavButtonValue}
                  >
                    <Radio name="1" value={1}>
                      Yes
                    </Radio>
                    <Radio name="0" value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="send-sample-content">
                <span>
                  <Radio.Group
                    onChange={onEmailStatusChange}
                    value={radioEmailButtonValue}
                  >
                    <Radio name="1" value={1}>
                      Yes
                    </Radio>
                    <Radio name="0" value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
              <div className="send-sample-content-files">
                <p className="upload-sec">
                <span>
                  <Button
                    icon={<UploadOutlined />}
                    onClick={() => fileRef.current.click()}
                  >
                    UPLOAD
                  </Button>
                  <input
                    type="file"
                    onChange={changeFileHandler}
                    accept="image/jpeg,image/png,image/jpg, application/pdf,application/.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={fileRef}
                    hidden
                  />
                  <div className="document-name">
                    {fileName ? (
                      fileName?.map((item, index) => {
                        console.log("filenames", item);
                        return (
                          <div className="document-section">
                            <p>{item}</p>
                            <CloseOutlined
                              twoToneColor="#000000"
                              onClick={(e) =>
                                onDeleteClick(item, index)
                              }
                            />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </span>
                </p>
              </div>
              <div className="send-sample-content-notes">
              <p className="text-area-sec">
                        <span>
                          <TextArea
                            rows={4}
                            placeholder="Type something"
                            value={SampleSendNotes}
                            onChange={onTextNoteChange}
                          />
                        </span>
                        <div className="srf-button-container">
                          <CommonButton
                            className="srf-button"
                            onClick={showModal}
                          >
                            Send Request
                          </CommonButton>
                        </div>
                        <div className="srf-modal">
                          <Modal
                            title=""
                            height="30"
                            width={600}
                            open={isModalOpen} onCancel={handleCancel}
                          >
                            <div className="srf-modal-content">
                              <div className="srf-modal-image-txt">
                                <p className="modal-tick">
                                  <img
                                    className="tick-image"
                                    src={CheckOne}
                                    alt="tick-logo"
                                  />
                                </p>
                                Confirm to send Sample Request ?
                                {/* <p className="colon-bold">{requestValue}</p> */}
                              </div>
                              <div className="sample-table-container">
                                <Table
                                  columns={SampleColumns}
                                  dataSource={SampleData}
                                />
                              </div>
                            </div>
                            <div className="srf-buttons">
                              <Button onClick={handleCancel}>No, cancel</Button>
                              <Button
                                className="modal-yes-button"
                                onClick={handleOk}
                              >
                                Yes, confirm
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </p>
              </div>
              </div> 
              </div>
              ) : (
                <div className="req-title">
                  <p>Please Submit the Colors to initiate a Sample Request.</p>
                </div>
              )}
            </div>
          </div>

          {/* <div className="req-one-main-sec">
            <div className="request-one-main">
              <p className="req-one-title">Send Sample Requests</p>
              {selectedFilteredDataList?.length > 0 ? (
                <div className="req-one-container">
                  <div className="bottom-section-main">
                    <div className="bottom-section">
                      <p className="bottom-section-color">
                        <span className="req-one-text">Request #</span> :{" "}
                      </p>
                      <p className="bottom-section-color">
                        <span className="req-one-text">Color Code</span> :{" "}
                      </p>
                      <p className="bottom-section-techpack">
                        <span className="req-one-text">Attach Tech Pack?</span> :
                      </p>
                      <p className="bottom-section-techpack">
                        <span className="req-one-text">Attach QAV?</span> :
                      </p>
                      <p className="bottom-section-techpack">
                        <span className="req-one-text">Send this SRF to Vendor?</span> :
                      </p>
                      <p className="bottom-section-files">
                        <span className="req-one-text">Attach Other Files?</span>{" "}
                        :{" "}
                      </p>
                      <p className="bottom-section-notes">
                        <span className="req-one-text">Request Notes</span> :{" "}
                      </p>
                    </div>
                    <div className="bottom-sec-two">
                      <div className="bottom-sec-select">
                        {filteredRequestNo.length === 1 ? (
                          <p>{filteredRequestNo[0]}</p>
                        ) : (
                          <Select
                            className="request-number-select-srf"
                            mode={"multiple"}
                            data={filteredRequestNo}
                            onChange={onRequestNoChangeHandle}
                          ></Select>
                        )}
                      </div>
                      <div className="bottom-sec-colorcode">
                        {filteredColorCode?.map((item) => {
                          return (
                            <p className="request-one-color-code">{item}</p>
                          );
                        })}
                      </div>
                      <p className="bottom-sec-radio">
                        <span>
                          <Radio.Group
                            onChange={onTpStatusChange}
                            value={radioButtonValue}
                          >
                            <Radio name="1" value={1}>
                              Yes
                            </Radio>
                            <Radio name="0" value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </span>
                      </p>
                      <p className="bottom-sec-radio">
                        <span>
                          <Radio.Group
                            onChange={onQavStatusChange}
                            value={radioQavButtonValue}
                          >
                            <Radio name="1" value={1}>
                              Yes
                            </Radio>
                            <Radio name="0" value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </span>
                      </p>
                      <p className="bottom-sec-radio">
                        <span>
                          <Radio.Group
                            onChange={onEmailStatusChange}
                            value={radioEmailButtonValue}
                          >
                            <Radio name="1" value={1}>
                              Yes
                            </Radio>
                            <Radio name="0" value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </span>
                      </p>
                      <p className="upload-sec">
                        <span>
                          <Button
                            icon={<UploadOutlined />}
                            onClick={() => fileRef.current.click()}
                          >
                            UPLOAD
                          </Button>
                          <input
                            type="file"
                            onChange={changeFileHandler}
                            accept="image/jpeg,image/png,image/jpg, application/pdf,application/.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            ref={fileRef}
                            hidden
                          />
                          <div className="document-name">
                            {fileName ? (
                              fileName?.map((item, index) => {
                                console.log("filenames", item);
                                return (
                                  <div className="document-section">
                                    <p>{item}</p>
                                    <CloseOutlined
                                      twoToneColor="#000000"
                                      onClick={(e) =>
                                        onDeleteClick(item, index)
                                      }
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        </span>
                      </p>
                      <p className="text-area-sec">
                        <span>
                          <TextArea
                            rows={4}
                            placeholder="Type something"
                            value={SampleSendNotes}
                            onChange={onTextNoteChange}
                          />
                        </span>
                        <div className="srf-button-container">
                          <CommonButton
                            className="srf-button"
                            onClick={showModal}
                          >
                            Send Request
                          </CommonButton>
                        </div>
                        <div className="srf-modal">
                          <Modal
                            title=""
                            height="30"
                            width={600}
                            open={isModalOpen} onCancel={handleCancel}
                          >
                            <div className="srf-modal-content">
                              <div className="srf-modal-image-txt">
                                <p className="modal-tick">
                                  <img
                                    className="tick-image"
                                    src={CheckOne}
                                    alt="tick-logo"
                                  />
                                </p>
                                Confirm to send Sample Request ?
                              </div>
                              <div className="sample-table-container">
                                <Table
                                  columns={SampleColumns}
                                  dataSource={SampleData}
                                />
                              </div>
                            </div>
                            <div className="srf-buttons">
                              <Button onClick={handleCancel}>No, cancel</Button>
                              <Button
                                className="modal-yes-button"
                                onClick={handleOk}
                              >
                                Yes, confirm
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="req-title">
                  <p>Please Submit the Colors to initiate a Sample Request.</p>
                </div>
              )}
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NewTable;
