import React, { useRef, useState } from "react";
import DropDown from "../../../UIComponents/DropDown/DropDown";
import "./UnsolicitedSamples.css";
import SearchInput from "../../../UIComponents/SearchInput/SearchInput";
import { Button } from "antd";
import SrtTable from "../Table/SrtTable";
import { useSelector, useDispatch } from "react-redux";
import {
    getSrtUnsolicitedSamplesList,
  massSearchFilter,
  resetMassFilter,
  resetMassUnsolicitedFilter,
  resetSeasonFilter,
  resetSrtData,
  seasonSearchFilter,
  selectUnselectSrtTableRows,
  srtBulkSave,
  srtExport,
  srTExport,
  srtSeasonSearchFilter,
  srtUnsolicitedBulkExport,
  srtUnsolicitedBulkUploadSave,
  unsolicitedMassFilter,
} from "../../../Redux/actions/Srt/Srt";
import Select from "../../../UIComponents/DropDown/DropDown";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { getSrtInitialList } from "../../../Redux/actions/Srt/Srt";
import { SyncOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { toast } from "../../../UIComponents/Toast";
import SalesTable from "../SalesSamples/SalesSamples";
import UnsolicitedTable from "./UnsolicitedTable";

const UnsolicitedSamples = () => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [searchFilter, setSearchFilter] = useState();
  const [requestFilter, setRequestFilter] = useState();
  const [teamFilter, setTeamFilter] = useState();
  const [referenceFilter, setReferenceFilter] = useState();
  const [receivedFilter, setReceivedFilter] = useState();
  const [sizeFilter, setSizeFilter] = useState();
  const [colorFilter, setColorFilter] = useState();
  const [vendorFilter, setVendorFilter] = useState();
  const [singleColor, setSingleColor] = useState([]);
  const [singleSeason, setSingleSeason] = useState([]);
  const [singleFactory, setSingleFactory] = useState([]);
  const [singleSize, setSingleSize] = useState([]);
  const [singleReference, setSingleReference] = useState([]);
  const [singleReceived, setSingleReceived] = useState([]);

  console.log("singleColor",singleSize,singleReceived,singleReference,singleColor,singleFactory);
  
  const exportArraySrt = useSelector((state) => state?.Srt?.srtExportArrayData);
  const exportArraySrtIds = useSelector((state) => state?.Srt?.srtSelectedTableRows);
  const srtRefresh = useSelector((state) => state?.Srt?.srtRefreshSpinner);
  const srtDataForApi = JSON.parse(localStorage.getItem("srtData"));
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);

  let srtUnsolicitedListData = useSelector((state) => state?.Srt?.srtUnsolicitedList);
  const massFilterApiData = useSelector(
      (state) => state?.Srt?.unsolicitedMassSearchFilterResult
  );
  const seasonFilterApiData = useSelector(
  (state) => state?.Srt?.seasonSearchFilterResult
);
const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  console.log("massFilterApiData",massFilterApiData);

  // useEffect(() => {
  //   dispatch(
  //       getSrtUnsolicitedSamplesList(
  //       srtDataForApi?.brand ? srtDataForApi?.brand : "",
  //       srtDataForApi?.style ? srtDataForApi?.style : "",
  //       srtDataForApi?.factory ? srtDataForApi?.factory : "",
  //       srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
  //       0
  //     )
  //   );
  // }, [
  //   srtDataForApi?.brand,
  //   srtDataForApi?.factory,
  //   srtDataForApi?.style,
  //   srtDataForApi?.rejected_date,
  // ]);

  const intialSearch = () => {
    if (massFilterApiData && massFilterApiData?.length > 0) {
      return massFilterApiData;
    } else if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
      return seasonFilterApiData;
    } else if (srtSeasonData && srtSeasonData?.length > 0) {
      return srtSeasonData;
    } else if (srtUnsolicitedListData) {
      return srtUnsolicitedListData;
    } else return [];
  };   

  useEffect(() => {
    const colorArray = intialSearch()?.map((item) => item?.color);
    setSingleColor([...new Set(colorArray)]);
    const sizeArray = intialSearch()?.map((item) => item?.size);
    setSingleSize([...new Set(sizeArray)]);
    const referenceArray = intialSearch()?.map((item) => item?.reference_number);
    setSingleReference([...new Set(referenceArray)]);
    const receivedArray = intialSearch()?.map((item) => item?.date_received);
    setSingleReceived([...new Set(receivedArray)]);
  },[intialSearch()])

  useEffect(() => {
      const vendorArray = intialSearch()?.map((item) => item?.factory_name);
      const uniqueNames = new Set();

      vendorArray.forEach((names) => {
        if (Array.isArray(names)) {
          names.forEach((name) => {
            uniqueNames.add(name);
          });
        } else {
          uniqueNames.add(names);
        }
      });

      const uniqueArray = Array.from(uniqueNames).sort();

      setSingleFactory(uniqueArray);
  }, [intialSearch()]);

  const requests = [
    {
      value: "",
      label: "-",
    },
    {
      value: 1,
      label: "Request 1",
    },
    {
      value: 2,
      label: "Request 2",
    },
    {
      value: 3,
      label: "Request 3",
    },
    {
      value: 4,
      label: "Request 4",
    },
    {
      value: 5,
      label: "Request 5",
    },
    {
      value: 6,
      label: "Request 6",
    },
    {
      value: 7,
      label: "Request 7",
    },
    {
      value: 8,
      label: "Request 8",
    },
    {
      value: 9,
      label: "Request 9",
    },
    {
      value: 10,
      label: "Request 10",
    },
  ];

  const team = [
    {
      value: "",
      label: "-",
    },
    {
      value: "BRAND",
      label: "BRAND",
    },
    {
      value: "DESIGN",
      label: "DESIGN",
    },
    {
      value: "PD",
      label: "PD",
    },
    {
      value: "QA",
      label: "QA",
    },
  ];

  const statusofsrt = [
    {
      value: "",
      label: "-",
    },
    {
      value: "Set_up_Ready",
      label: "Set-up Ready",
    },
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Override",
      label: "Override",
    },
    {
      value: "N/A",
      label: "N/A",
    },
    {
      value: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
      label: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
    },
  ];

  const onSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onRequestChange = (value) => {
    setRequestFilter(value);
  };

  const onTeamChange = (value) => {
    setTeamFilter(value);
  };

  const onColorChange = (value) => {
    setColorFilter(value)
  }

  const onSizeChange = (value) => {
    setSizeFilter(value)
  }

  const onReferenceChange = (value) => {
    setReferenceFilter(value);
  };
  
  const onReceivedChange = (value) => {
    setReceivedFilter(value);
  };

  const onSeasonChange = (value) => {
    dispatch(srtSeasonSearchFilter("", value == undefined ? "" : value, 0));
    dispatch(resetMassFilter([]));
    dispatch(resetSrtData([]));
  };

  const onSubmitClick = () => {
    if (!searchFilter && !vendorFilter && !colorFilter && !referenceFilter && !receivedFilter && !sizeFilter) {
      toast.error("!Please select season");
    }
    else {
    dispatch(
      unsolicitedMassFilter(
        searchFilter ? searchFilter : "",
        vendorFilter ? vendorFilter : "",
        colorFilter ? colorFilter : "",
        referenceFilter ? referenceFilter : "",
        receivedFilter ? receivedFilter : "",
        sizeFilter ? sizeFilter : "",
      )
    );
  }
  };

  const exportToExcelDickies = () => {
    dispatch(srtUnsolicitedBulkExport({ ids: exportArraySrtIds }));
    dispatch(selectUnselectSrtTableRows([]));
    dispatch(srtEXportArray([]));
  };

  const onRefresh = () => {
    localStorage.removeItem("srtMassData");
    localStorage.removeItem("currentPage");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "",
        style: "",
        factory: "",
        rejected_date: "",
        sale_sample: 0
      })
    );
    dispatch(
        getSrtUnsolicitedSamplesList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0
      )
    );
    dispatch(resetSeasonFilter([]));
    dispatch(resetMassUnsolicitedFilter([]));
    setSearchFilter();
    setRequestFilter();
    setTeamFilter();
    setReferenceFilter();
    setVendorFilter();
  };

  const onFactoryChange = (value) => {
    setVendorFilter(value);
  };
  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      if (searchFilter === null) {
        toast.error("Please Enter Details");
      } else {
        localStorage.setItem(
          "srtMassData",
          JSON.stringify({
            searchFilter: searchFilter,
            brandfilter: "",
            requestfilter: "",
            teamFilter: "",
            referenceFilter: "",
            vendorFilter: "",
            sale_samplefilter: 0
          })
        );
        dispatch(
          massSearchFilter(
            searchFilter ? searchFilter : "",
            "",
            "",
            "",
            "",
            "",
            0
          )
        );
        setSearchFilter();
      }
    }
  };

  const onSrtBulkUpload = (e) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    dispatch(srtUnsolicitedBulkUploadSave(formData, brandNameFromApi));
    e.target.value = null;
    formData = new FormData();
  };  

  return (
    <div className="dickies-main-container">
      <div className="dickies-first-two-dropdowns">
        <div></div>
        <h2>UNSOLICITED SAMPLES</h2>
        
        <div className="dickies-drop-down-two">
          <Button icon={<DownloadOutlined />} onClick={exportToExcelDickies}>
            Export
          </Button>
        </div>
      </div>
      <div className="search-dropdown-button">
        <div className="sync-icon">
          {/* <SyncOutlined spin={srtRefresh} onClick={onRefresh} /> */}
        </div>
        <div>
          <Button
            className="dickies-button"
            onClick={() => fileRef.current.click()}
          >
            Bulk Update
          </Button>
          <input
            type="file"
            onChange={onSrtBulkUpload}
            accept="image/jpeg/png/jpg , application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={fileRef}
            hidden
          />
        </div>
      </div>
      <div className="search-dropdown-button">
        <div className="dickies-search-input">
          <SearchInput
            maxLength={10}
            onKeyPress={onSearchEnter}
            onChange={onSearchChange}
            value={searchFilter}
            placeholder={"Search by Parent Style"}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            }}
          />
        </div>
        <div className="unsolicited-dropdown-width unsolicited-dickies-drop-down-one">
          <Select
            allowClear
            placeholder={"Vendor"}
            data={singleFactory}
            value={vendorFilter}
            onChange={onFactoryChange}
          ></Select>
        </div>
        <div className="unsolicited-dropdown-width unsolicited-dickies-drop-down-two">
          <Select
              allowClear
              placeholder={"Color"}
              data={singleColor}
              value={colorFilter}
              onChange={onColorChange}
            ></Select>
        </div>
        <div className="unsolicited-dropdown-width unsolicited-dickies-drop-down-three">
          <Select
            allowClear
            placeholder={"Size"}
            data={singleSize}
            value={sizeFilter}
            onChange={onSizeChange}
          ></Select>
        </div>
        <div className="unsolicited-dropdown-width unsolicited-dickies-drop-down-four">
        {/* <Select
            allowClear
            placeholder={"Reference Number"}
            data={singleReference}
            value={referenceFilter}
            onChange={onReferenceChange}
          ></Select> */}
        </div>
        <div className="unsolicited-dropdown-width unsolicited-dickies-drop-down-five">
        <Select
            allowClear
            placeholder={"Received Filter"}
            data={singleReceived}
            value={receivedFilter}
            onChange={onReceivedChange}
          ></Select>
        </div>
        <div className="sync-icon">
          <SyncOutlined spin={srtRefresh} onClick={onRefresh} />
        </div>
        <div>
          <Button
            onClick={onSubmitClick}
            className="dickies-button"
            children={"SUBMIT"}
          />
        </div>

      </div>
      <div className="srt-table-container">
        <UnsolicitedTable />
      </div>
    </div>
  );
};

export default UnsolicitedSamples;
