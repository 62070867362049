import { Button, Table } from "antd";
import React, { useState, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Brand from "./Brand/Brand";
import DateSentReceive from "./DateSentRec/DateSentRec";
import Design from "./Design/Design";
import ExFactoryDate from "./ExFactoryDate/ExFactoryDate";
import ParentStyle from "./ParentStyle/ParentStyle";
import PD from "./PD/PD";
import QA from "./QA/QA.js";
import QtyReqRec from "./QtyReqRec/QtyReqRec";
import Request from "./Request/Request";
import "./SrtTable.css";
import BeatLoader from "react-spinners/BeatLoader";
import { useEffect } from "react";
import {
  selectUnselectSrtTableRows,
  srtEXportArray,
  srtRowIndexForAutoScroll,
} from "../../../Redux/actions/Srt/Srt";

const SrtTable = () => {
  const dispatch = useDispatch();
  let srtDataList = useSelector((state) => state?.Srt?.getSrtDataList);
  const massFilterApiData = useSelector(
    (state) => state?.Srt?.massSearchFilterResult
  );
  const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSearchLoader = useSelector((state) => state?.Srt?.searchLoader);
  const srtRowIndexScroll = useSelector((state) => state?.Srt?.srtRowIndex);
  const statusVar = useSelector((state) => state?.Srt?.brandComment?.status);
  const commentUpdate = useSelector(
    (state) => state?.Srt?.srtCommentUpdate?.status
  );
  const saveVar = useSelector((state) => state?.Srt?.srtSaveAllData?.status);
  console.log("statussave", saveVar, statusVar, commentUpdate);
  const selectedUnselectedRows = useSelector(
    (state) => state?.Srt?.srtSelectedTableRows
  );
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  const exportArrayData = useSelector(
    (state) => state?.Srt?.srtExportArrayData
  );
  const srtScrollPosition = localStorage.getItem("srtAutoScroll");
  console.log("exportArrayData", selectedUnselectedRows, exportArrayData);

  const intialSearch = () => {
    if (massFilterApiData && massFilterApiData?.length > 0) {
      return massFilterApiData;
    } else if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
      return seasonFilterApiData;
    } else if (srtSeasonData && srtSeasonData?.length > 0) {
      return srtSeasonData;
    } else if (srtDataList) {
      return srtDataList;
    } else return [];
  };

  const columns = [
    {
      title: "Parent Style",
      dataIndex: "ParentStyle",
      width: "24%",
    },
    {
      title: "Request",
      dataIndex: "Request",
    },
    {
      title: "Ex-Factory Date Req/Promised",
      dataIndex: "ExFactoryDateReqPromised",
    },
    {
      title: "Date Sent/Received",
      dataIndex: "DateSentReceive",
    },
    {
      title: "Qty Requested/Received",
      dataIndex: "QtyRequestReceive",
    },
    {
      title: "BRAND",
      dataIndex: "BRAND",
    },
    {
      title: "DESIGN",
      dataIndex: "DESIGN",
    },
    {
      title: "PD",
      dataIndex: "PD",
    },
    {
      title: "QA",
      dataIndex: "QA",
    },
  ];

  console.log("location.pathname", location.pathname);

  const path = location.pathname;

  if (path.includes("/sample-receipt-tracker/dickies/Dropped-Rejected")) {
    srtDataList.forEach((item) => {
      item.request_details = item.request_details.filter((detail) => {
        return (
          detail.brand_status === "Dropped" ||
          detail.design_status === "Rejected" ||
          detail.pd_status === "Rejected" ||
          detail.qa_status === "Rejected"
        );
      });
    });
    srtDataList = srtDataList.filter((item) => item.request_details.length > 0);
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "1");
  } else if (
    path.includes("/sample-receipt-tracker/saucony/Dropped-Rejected")
  ) {
    srtDataList.forEach((item) => {
      item.request_details = item.request_details.filter((detail) => {
        return (
          detail.brand_status === "Dropped" ||
          detail.design_status === "Rejected" ||
          detail.pd_status === "Rejected" ||
          detail.qa_status === "Rejected"
        );
      });
    });
    srtDataList = srtDataList.filter((item) => item.request_details.length > 0);
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "2");
  } else if (
    path.includes("/sample-receipt-tracker/eddie-bauer/Dropped-Rejected")
  ) {
    srtDataList.forEach((item) => {
      item.request_details = item.request_details.filter((detail) => {
        return (
          detail.brand_status === "Dropped" ||
          detail.design_status === "Rejected" ||
          detail.pd_status === "Rejected" ||
          detail.qa_status === "Rejected"
        );
      });
    });
    srtDataList = srtDataList.filter((item) => item.request_details.length > 0);
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "3");
  } else if (path.includes("/sample-receipt-tracker/pd/Dropped-Rejected")) {
    srtDataList.forEach((item) => {
      item.request_details = item.request_details.filter((detail) => {
        return (
          detail.brand_status === "Dropped" ||
          detail.design_status === "Rejected" ||
          detail.pd_status === "Rejected" ||
          detail.qa_status === "Rejected"
        );
      });
    });
    srtDataList = srtDataList.filter((item) => item.request_details.length > 0);
    localStorage.setItem("activeKey", "4");
    localStorage.setItem("srtActiveKey", "4");
  } else srtDataList;

  console.log("srtDataList", srtDataList);

  const data = [];
  {
    intialSearch()?.map((item, index) => {
      return data.push({
        key: index,
        ParentStyle: (
          <ParentStyle
            sockId={item?.style}
            sockName={item?.description}
            vendor={item?.factory}
            color={item?.color_code}
            size={item?.size}
            promsiedExFactory={item}
            brand={item?.brand}
            season={item?.season}
          />
        ),
        Request: item?.request_details?.map((data, subIndex) => {
          return <Request request={data?.request_no} />;
        }),

        ExFactoryDateReqPromised: item?.request_details?.map(
          (data, subIndex) => {
            return (
              <ExFactoryDate
                requestDate={data?.req_exfactory}
                createdDate={data.created_date}
                requestRecievedDate={data?.promised_exfactory}
                dataOfReq={data}
                itemData={item}
                salesSample="0"
              />
            );
          }
        ),

        DateSentReceive: item?.request_details?.map((data, subIndex) => {
          return (
            <DateSentReceive
              reqNo={data?.request_no}
              createdDate={data.created_date}
              dataOfReq={data}
              recieveDate={data?.request_date}
              requestRecievedDate={data?.request_recieved}
              itemData={item}
              salesSample="0"
            />
          );
        }),

        QtyRequestReceive: item?.request_details?.map((data, subIndex) => {
          return (
            <>
              <QtyReqRec
                data={data}
                user={data?.user}
                promisedDate={data?.promised_exfactory}
                requestRecievedDate={data?.request_recieved}
                qtyReciever={data?.Qty}
                qtyRecieved={data?.QtyRcd}
                commentReciever={data?.quantity_cmt}
                dataOfRow={item}
                dataOfReq={data}
                reqNo={data?.request_no}
                itemData={item}
                salesSample="0"
              />
            </>
          );
        }),

        BRAND: item?.request_details?.map((data, subIndex) => {
          console.log("brandquantity", data);
          return (
            <Brand
              user={data?.user}
              data={data}
              brandStatus={data?.brand_status}
              statusFor="brand"
              dataOfRow={item}
              reqNo={data?.request_no}
              commentReciever={data?.brand_cmt}
              salesSample="0"
            />
          );
        }),

        DESIGN: item?.request_details?.map((data, subIndex) => {
          return (
            <Design
              user={data?.user}
              data={data}
              designStatus={data?.design_status}
              statusFor="design"
              dataOfRow={item}
              reqNo={data?.request_no}
              commentReciever={data?.design_cmt}
              salesSample="0"
            />
          );
        }),

        PD: item?.request_details?.map((data, subIndex) => {
          return (
            <PD
              user={data?.user}
              data={data}
              pdStatus={data?.pd_status}
              dataOfRow={item}
              statusFor="pd"
              reqNo={data?.request_no}
              commentReciever={data?.pd_cmt}
              salesSample="0"
            />
          );
        }),

        QA: item?.request_details?.map((data, subIndex) => {
          return (
            <QA
              user={data?.user}
              data={data}
              qaStatus={data?.qa_status}
              dataOfRow={item}
              statusFor="qa"
              reqNo={data?.request_no}
              commentReciever={data?.qa_cmt}
              salesSample="0"
            />
          );
        }),
      });
    });
  }

  const onSelectChange = (newSelectedRowKeys, record) => {
    console.log("newSelectedRowKeys, record",newSelectedRowKeys, record);
    const pidArray = [];
    const brandArray = record?.map((item) => {
      pidArray.push(item.BRAND[0].props.dataOfRow.style_pid);
    });
    dispatch(srtEXportArray(pidArray));
    dispatch(selectUnselectSrtTableRows(newSelectedRowKeys));
  };

  const rowSelection = {
    selectedUnselectedRows,
    onChange: onSelectChange,
  };
  const handleRowClick = (record, rowIndex) => {
    dispatch(srtRowIndexForAutoScroll(rowIndex));
  };

  const pageSize = 10;

  const initialPage = localStorage.getItem("currentPage") || 1;
  const defaultCurrent = parseInt(initialPage, 10);

  const paginationConfig = {
    pageSize,
    defaultCurrent,
    onChange: (page) => handlePageChange(page),
  };
  const [currentPage, setCurrentPage] = useState(defaultCurrent);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const containerRef = useRef(null);

  const handleScroll = () => {
    console.log("handlescrolldata");
    
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      console.log("scrolled postion", scrollTop);
      localStorage.setItem("srtAutoScroll", scrollTop);
    }
  };

  const handleScrollButton = () => {
    if (srtScrollPosition) {
      window.scrollTo({
        top: containerRef.current.offsetTop,
      });
      containerRef.current.scrollTo({
        top: srtScrollPosition,
      });
    }
  };

  useEffect(() => {
    console.log("scroll position of localstorage", srtScrollPosition);
    handleScrollButton();
  }, [srtDataList,massFilterApiData,seasonFilterApiData,srtSeasonData]);

  return (
    <>
      {srtSearchLoader ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={srtSearchLoader}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div ref={containerRef} onScroll={handleScroll} className="srt-table">
          <Table
            onMouseEnter={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              ...paginationConfig,
              current: currentPage,
            }}
            rowClassName={() => "row"}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleRowClick(record, rowIndex);
                },
              };
            }}
          />
        </div>
      )}
    </>
  );
};

export default SrtTable;
